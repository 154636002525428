export enum AllocationCategory {
  Billable = 1,
  Shadow = 2,
  DomainTraining = 3,
  FresherTraining = 4,
  Support = 5,
  Internal = 6,
  TalentPool = 7,
  Management = 8,
  Others = 9
}

export enum PositionType {
    Billable = 1,
    InternalApp = 14,
    POC = 15,
    Resignation = 16,
    Leave = 17,
    Rotation = 18,
    SupportShadow = 10,
    PriorityShadow = 11,
    AliasBillableShadow = 19,
    DualBillableShadow = 13,
    TalentPool = 7
}

export enum ResourceRequestStatus {
    Open = 1,
    InProgress = 2,
    Identify = 3,
    OnHold = 4,
    Cancelled = 5,
    Fulfilled = 6,
    ReferredToTA = 7,
  }

 export function getEnumKeyByEnumValue(myEnum: any, enumValue: number): string {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : '';
  }

  

  export const resourceRequestStatus = {
    Open : "Open",
    InProgress : "In Progress",
    Identify : "Identify",
    OnHold : "On Hold",
    Cancelled : "Cancelled",
    Fulfilled : "Fulfilled",
    ReferredToTA : "Referred To TA",
  }

  const ResourceRequestStatusStrings: { [key in ResourceRequestStatus]: string } = {
    [ResourceRequestStatus.Open]: resourceRequestStatus.Open,
    [ResourceRequestStatus.InProgress]: resourceRequestStatus.InProgress,
    [ResourceRequestStatus.Identify]: resourceRequestStatus.Identify,
    [ResourceRequestStatus.OnHold]: resourceRequestStatus.OnHold,
    [ResourceRequestStatus.Cancelled]: resourceRequestStatus.Cancelled,
    [ResourceRequestStatus.Fulfilled]: resourceRequestStatus.Fulfilled,
    [ResourceRequestStatus.ReferredToTA]: resourceRequestStatus.ReferredToTA,
  };
  
  export function getResourceRequestStatusString(value: ResourceRequestStatus = ResourceRequestStatus.Open): string {
    return ResourceRequestStatusStrings[value] || "Open";
  }

  export function getColorCodesForResourceRequestStatus(resourceRequestStatus:ResourceRequestStatus){
    if([ResourceRequestStatus.Open,ResourceRequestStatus.InProgress,ResourceRequestStatus.Identify,ResourceRequestStatus.ReferredToTA].includes(resourceRequestStatus))
    {
      return "waiting";
    } else if([ResourceRequestStatus.OnHold,ResourceRequestStatus.Cancelled].includes(resourceRequestStatus))
    {
      return "rejected";
    } else if([ResourceRequestStatus.Fulfilled].includes(resourceRequestStatus))
    {
      return "completed";
    }
  }
  

  export function getColorCodesFromStatusString(statusString: string) {
    switch(statusString) {
      case resourceRequestStatus.Open:
      case resourceRequestStatus.InProgress:
      case resourceRequestStatus.Identify:
      case resourceRequestStatus.ReferredToTA:
        return 'waiting';
      case resourceRequestStatus.OnHold:
      case resourceRequestStatus.Cancelled:
        return 'rejected';
      case resourceRequestStatus.Fulfilled:
        return 'completed';
      default:
        return 'unknown';
    }
  }

  export enum TimeLinesTypesEnum {
    Accounts = 1,
    Projects = 2,
    Resource = 3,
    Employee = 8,
    ResourceRequest = 9
  }

  export type TimeLinesPropTypes =  "Account" | "ResourceRequest" | "Resource" | "Project" | "Employee";

  
