import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import WithAuthorizationCheck from '../authorization/WithAuthorizationCheck';
import {ACCOUNT_READ, PROJECT_READ, EMPLOYEES_READ, RESOURCES_READ, RESOURCES_ADD, RESOURCE_REQUEST_RECOMMEND, RESOURCE_REQUEST_ASSIGN, RESOURCE_REQUEST_ADD, RESOURCE_REQUEST_READ, RESOURCE_REQUEST_EDIT} from '../authorization/PermissionsConstants'
import Layout from "../../views/components/layout/layout";
import UnAuthorized from "../../views/components/UnAuthorized/UnAuthorized";
import PrivacyPage from "../../views/components/Terms/PrivacyPage";
import TermsOfUsePage from "../../views/components/Terms/TermsOfUsePage";
import {EmployeeDocuments} from '../../views/components/Employee/EmployeeDocuments/EmployeeDocuments'
import { EmployeeActivities } from '../../views/components/Employee/EmployeeActivities/EmployeeActivities';
import {DocumentTypeEnum} from '../../enums/DocumentTypes';
import { TIMELINE_PROPTYPES } from "../../constants/appConstants";

const LazyAccountOverview = React.lazy(() => import(/* webpackChunkName: "AccountOverviewComponent" */ "../../views/components/Account/AccountOverview/AccountOverview"));
const LazyAccountLanding = React.lazy(() => import(/* webpackChunkName: "AccountLandingComponent" */ "./../../views/components/Account/AccountLanding/AccountLanding"));
const LazyProjectLanding = React.lazy(() => import(/* webpackChunkName: "ProjectLandingComponent" */ "../../views/components/Project/ProjectLanding/ProjectLanding"));
const LazyResourceRequestsLanding = React.lazy(() => import(/* webpackChunkName: "ResourceRequestsComponent" */ "../../views/components/ResourceRequests/ResourceRequestsLanding/ResourceRequestsLanding"));
const LazyEmployeeLanding = React.lazy(() => import(/* webpackChunkName: "EmployeeLandingComponent" */ "./../../views/components/Employee/EmployeeLanding/EmployeeLanding"));
const LazyAccounts360 = React.lazy(() => import(/* webpackChunkName: "Account360Component" */ "../../views/components/Account/Accounts360/Accounts360"));
const LazyContacts = React.lazy(() => import(/* webpackChunkName: "ContactsComponent" */ "../../views/components/Account/Contacts/Contacts"));
const LazyAccountsTimeLine = React.lazy(() => import(/* webpackChunkName: "TimeLineComponent" */ "../../views/components/Account/TimeLine/TimeLine"));
const LazyResources = React.lazy(() => import(/* webpackChunkName: "ResourcesComponent" */ "../../views/components/Account/Resources/Resources"));
const LazyProjects = React.lazy(() => import(/* webpackChunkName: "ProjectsComponent" */ "../../views/components/Account/Projects/Projects"));
const LazyEmployee360 = React.lazy(() => import(/* webpackChunkName: "Employee360omponent" */ "../../views/components/Employee/Employee360/Employee360"));
const LazyEmployeeOverview = React.lazy(() => import(/* webpackChunkName: "EmployeeOverviewComponent" */"../../views/components/Employee/EmployeeOverview/EmployeeOverview"));
const LazyEmployeeProjects = React.lazy(() => import(/* webpackChunkName: "EmployeeProjectsComponent" */ "../../views/components/Employee/Projects/EmployeeProjects"));
const LazyEmployeeSkillTracking = React.lazy(() => import(/* webpackChunkName: "EmployeeSkillTracking" */ "../../views/components/Employee/EmployeeSkillTracking/EmployeeSkillTracking"))
const LazyProjects360 = React.lazy(() => import(/* webpackChunkName: "Projects360Component" */ "../../views/components/Project/Projects360/Projects360"));
const LazyProjectOverview = React.lazy(() => import(/* webpackChunkName: "ProjectOverviewComponent" */ "../../views/components/Project/ProjectOverview/ProjectOverview"));
const LazyProjectDocuments = React.lazy(() => import(/* webpackChunkName: "ProjectDocumentsComponent" */ "../../views/components/Project/ProjectDocuments/projectdocuments"));
const LazyProjectDocumentsList = React.lazy(() => import(/* webpackChunkName: "DocumentsListComponent" */ "../../views/components/Project/ProjectDocuments/DocumentsList"));
const LazyResourceRequests = React.lazy(() => import(/* webpackChunkName: "RequestsComponent" */"../../views/components/ResourceRequests/Requests/Requests"));
const LazyResourceRequestCreateOrUpdate = React.lazy(() => import(/* webpackChunkName: "ResourceRequestOverviewComponent" */"../../views/components/ResourceRequests/ResourceRequestOverview/ResourceRequestOverview"));
const LazyResourceRequestDetailsView = React.lazy(() => import(/* webpackChunkName: "ResourceRequestOverviewComponent" */"../../views/components/ResourceRequests/RequestDetailsOverview/RequestDetailsView"));
const LazyResourceRequestDetailsOverView = React.lazy(() => import(/* webpackChunkName: "ResourceRequestOverviewComponent" */"../../views/components/ResourceRequests/RequestDetailsOverview/RequestDetailsOverview"));

function RoutesConfig() {
  return (
    <Routes>
      <Route path="/*" element={<Layout />}>
        <Route path="home" element={<></>} />
        <Route
          path="employee"
          element={
              <WithAuthorizationCheck requiredPermissions={[EMPLOYEES_READ]}>
                <LazyEmployeeLanding />
              </WithAuthorizationCheck>
          }
        />
        <Route
          path="accounts"
          element={
              <WithAuthorizationCheck requiredPermissions={[ACCOUNT_READ]}>
                <LazyAccountLanding />
              </WithAuthorizationCheck>
          }
        />
        <Route
          path="projects"
          element={
              <WithAuthorizationCheck requiredPermissions={[PROJECT_READ]}>
                <LazyProjectLanding />
              </WithAuthorizationCheck>
          }
        />
        <Route
          path="resource-requests"
          element={
              <WithAuthorizationCheck requiredPermissions={[RESOURCE_REQUEST_READ]}>
                <LazyResourceRequestsLanding />
              </WithAuthorizationCheck>
          }
        >
          <Route index element={<Navigate to="my-requests" />} />
          <Route path="my-requests" element={ <LazyResourceRequests type="my-requests"/> }/>
          <Route path="assigned-requests" element={ <LazyResourceRequests type="assigned-requests"/> } />
        </Route>
        <Route path="resource-requests/my-requests/edit/:id" element={
          <WithAuthorizationCheck requiredPermissions={[RESOURCE_REQUEST_EDIT]}>
            <LazyResourceRequestCreateOrUpdate type='Allocate'/> 
          </WithAuthorizationCheck> 
          }
        />
        <Route path="resource-requests/assigned-requests/edit/:id" element={ 
          <WithAuthorizationCheck requiredPermissions={[RESOURCE_REQUEST_EDIT]}>
            <LazyResourceRequestCreateOrUpdate type='Recommend'/> 
          </WithAuthorizationCheck>
          } 
        />
         <Route path="resource-requests/assigned-requests/details/:id" element={
          <WithAuthorizationCheck requiredPermissions={[RESOURCE_REQUEST_READ]}>
            <LazyResourceRequestDetailsView/> 
          </WithAuthorizationCheck> 
          }
        >
          <Route path="overview" element={ <LazyResourceRequestDetailsOverView /> } />
          <Route path="timeline" element={ <LazyAccountsTimeLine level={TIMELINE_PROPTYPES.ResourceRequest} /> } />
        </Route>
         <Route path="resource-requests/my-requests/details/:id" element={
          <WithAuthorizationCheck requiredPermissions={[RESOURCE_REQUEST_READ]}>
            <LazyResourceRequestDetailsView/> 
          </WithAuthorizationCheck> 
          }
        >
            <Route index element={ <LazyResourceRequestDetailsOverView /> } />
           <Route path="overview" element={ <LazyResourceRequestDetailsOverView /> } />
           <Route path="timeline" element={ <LazyAccountsTimeLine level={TIMELINE_PROPTYPES.ResourceRequest} /> } />
           </Route>
        <Route path="resource-requests/create-request" element={ 
          <WithAuthorizationCheck requiredPermissions={[RESOURCE_REQUEST_ADD]}>
            <LazyResourceRequestCreateOrUpdate type='Recommend'/> 
          </WithAuthorizationCheck>
          } 
          />
        <Route path="resource-requests/create-request/clone/:cloneRequestId" element={ 
          <WithAuthorizationCheck requiredPermissions={[RESOURCE_REQUEST_ADD]}>
            <LazyResourceRequestCreateOrUpdate type='Recommend'/> 
          </WithAuthorizationCheck>
          } 
          />
        <Route path="*" element={<Navigate to="/accounts" replace />} />
        <Route
          path="accounts/:id/accounts360"
          element={
              <WithAuthorizationCheck requiredPermissions={[ACCOUNT_READ]}>
                <LazyAccounts360 />
              </WithAuthorizationCheck>
          }
        >
          <Route path="overview" element={ <LazyAccountOverview /> } />
          <Route path="contacts" element={ <LazyContacts level="Account" /> } />
          <Route path="timeline" element={ <LazyAccountsTimeLine level={TIMELINE_PROPTYPES.Accounts} /> } />
          <Route path="projects" element={ <LazyProjects /> } />
          <Route path="resources" element={ <LazyResources level="Account" /> } />
        </Route>
        <Route
          path="employee/:id/employee360"
          element={
              <WithAuthorizationCheck requiredPermissions={[EMPLOYEES_READ]}>
                <LazyEmployee360 />
              </WithAuthorizationCheck>
          }
        >
          <Route path="overview" element={ <LazyEmployeeOverview /> } />
          <Route path="projects" element={ <LazyEmployeeProjects /> } />
          
          <Route path="timeline" element={ <LazyAccountsTimeLine level={TIMELINE_PROPTYPES.Employee} /> } />
          <Route path="skillTracking" element={<LazyEmployeeSkillTracking />} />
          <Route path="timeline" element={ <LazyAccountsTimeLine level="Employee" /> } />
          <Route path="documents" element={ <EmployeeDocuments /> } />
          <Route path="resourceActivities" element={<EmployeeActivities/>} />
        </Route>
        <Route
          path="projects/:id/projects360"
          element={
              <WithAuthorizationCheck requiredPermissions={[PROJECT_READ]}>
                <LazyProjects360 />
              </WithAuthorizationCheck>
          }
        >
          <Route path="overview" element={ <LazyProjectOverview /> } />
          <Route path="contacts" element={ <LazyContacts level="Project" /> } />
          <Route path="timeline" element={ <LazyAccountsTimeLine level={TIMELINE_PROPTYPES.Project} /> } />
          <Route path="resources" element={ <LazyResources level="Project" /> } />
          <Route path="documents" element={ <LazyProjectDocuments /> }>
            <Route path="pmo" element = { <LazyProjectDocumentsList documentCategory = {DocumentTypeEnum.ProjectPMO} />} />
            <Route path="kt" element = { <LazyProjectDocumentsList documentCategory = {DocumentTypeEnum.ProjectKT} />} />
            <Route path="monthly" element = { <LazyProjectDocumentsList documentCategory = {DocumentTypeEnum.ProjectMontlyReports} />} />
          </Route>
        </Route>
      </Route>
      <Route path="/unauthorized" element={<UnAuthorized />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/termsofuse" element={<TermsOfUsePage />} />
    </Routes>
  );
}




export default RoutesConfig;
