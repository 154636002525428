import { formatDate } from '../../../helpers/formatdate';
import { ResourceRequestStatus } from '../../../enums/common';

export default class ResourceRequest {
  resourceRequestId?: number;
  requestType?: number;
  requestStatus?: number = Number(ResourceRequestStatus.Open);
  resourceRequiredBy?: Date;
  accountId?: number;
  projectId?: number;
  projectType?: number;
  practiceId?: number;
  noOfResourcesRequired: number = 1;
  skillSet?: number;
  positionTypeId?: number;
  purposeOfRequest ?: number;
  purposeDetails ?: string;
  probability ?: number;
  gaveUpStatus?: number;
  experienceId ?: number;
  startDate: Date | undefined = new Date();
  expectedFullfillment?: Date | undefined;
  duration?: number;
  leadTime?: number;
  priorityId?: number;
  comments ?: string;
  pmoNotes ?: string;
  recommendedResources?: number[] = [];
  identifiedResources?: number[] = [];
  assignedResources?: number[] = [];
  shadowOfId?: number;
  shadowTypeId?: number;
  shiftTimingId?: number;
  roleId?: number;
  billingHours?: number;
  requestedBy? : string;
  billabilityPercentage?: number;
  utilizationPercentage?: number;
}
